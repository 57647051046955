<template>
  <div
    class="quota-item"
    :class="{ 'in-active': !quota.edit }"
    @click="$store.commit('quota/SET_CURRENT_QUOTA_NAME', quota.name)"
  >
    <div class="quota-item__header">
      <div class="quota-item__header--sum">{{ price }} €</div>
      <div class="quota-item__header--title">
        <div v-if="!editMode">
          <span>{{ quotaName }}</span>
          <div class="quota-item__header--title-status">
            <span>{{ status }}</span>
            <component :is="statusIcon" size="21"></component>
          </div>
        </div>
        <div v-else class="reset-input__wrapper">
          <input
            :ref="`quota_id-${index}`"
            class="reset-input"
            type="text"
            v-model="quotaName"
          />
        </div>
      </div>

      <v-menu rounded="lg" offset-x v-if="!editMode">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="grey"
            icon
            class="mdi-dots-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link :to="routerLink">
            <v-list-item-icon>
              <v-icon color="primary" style="font-size: 20px"
                >mdi-eye-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title>Aperçu</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="editMode = true" :disabled="editMode">
            <v-list-item-icon>
              <IconEdit20px />
            </v-list-item-icon>
            <v-list-item-title>Renommer le projet</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="dialog = true">
            <v-list-item-icon>
              <IconDelit20px style="margin-left: -3px" />
            </v-list-item-icon>
            <v-list-item-title>Supprimer le projet</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="quota-item__header--save" v-else>
        <v-btn
          v-if="editMode"
          :loading="loading"
          text
          icon
          @click="updateQuota()"
        >
          <v-icon color="primary">mdi-check-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="quota-item__rooms">
      <v-chip-group column>
        <v-chip
          v-for="(room, index) in rooms"
          :key="`chip-${index}`"
          color="primary"
          outlined
        >
          {{ room.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <router-link :to="routerLink" class="quota-item__footer">
      <div class="quota-item__footer--icon">
        <IconConstructionToolsPencil />
      </div>
    </router-link>
    <ConfirmDialog
      :show="dialog"
      question="Voulez-vous vraiment supprimer <br>le projet ?"
      @closeModal="dialog = false"
      @confirmModalAgree="deleteQuota"
    />
  </div>
</template>

<script>
import { QuotaService } from "@/services/quotas.service.js";
export default {
  name: "QuotaItem",
  props: {
    quota: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    IconConstructionToolsPencil: () =>
      import("~cp/Icons/IconConstructionToolsPencil"),
    IconEdit20px: () => import("~cp/Icons/IconEdit20px"),
    IconDelit20px: () => import("~cp/Icons/IconDelit20px"),
    ConfirmDialog: () => import("~ui/ConfirmDialog/ConfirmDialog.vue"),
    IconQuotaNotValid: () => import("~cp/Icons/IconQuotaNotValid"),
    IconQuotaValid: () => import("~cp/Icons/IconQuotaValid"),
    IconQuotaEdit: () => import("~cp/Icons/IconQuotaEdit"),
  },
  data() {
    return {
      editMode: false,
      loading: false,
      dialog: false,
      quotaName: "",
      rooms: this.quota.quota_rooms ? this.quota.quota_rooms : [],
    };
  },
  computed: {
    price() {
      return this.quota?.price[2] ? this.quota.price[2].toFixed(2) : this.quota?.price[1].toFixed(2) || "0.00";
    },
    routerLink() {
      if (this.quota?.edit) {
        return {
          name: "RoomId",
          query: {
            project_id: this.quota.project_id,
            quota_id: this.quota.id,
          },
          params: {
            roomId:
              this.quota.quota_rooms && this.quota.quota_rooms.length > 0
                ? this.quota.quota_rooms[0].id
                : "new",
          },
        };
      }
      if (!this.quota?.edit) {
        return {
          name: "QuotaSum",
          params: {
            quotaId: this.quota.id,
          },
        };
      }
    },
    status() {
      return this.$store?.state.quota?.statuses[this.quota.custom_status];
    },
    statusIcon() {
      switch (this.quota.custom_status) {
        case 3:
        case 4:
        case 6:
          return "IconQuotaNotValid";
        case 5:
        case 7:
          return "IconQuotaValid";
        default:
          return "IconQuotaEdit";
      }
    },
  },
  watch: {
    quota: {
      immediate: true,
      handler(newVal) {
        this.quotaName = newVal.name;
      },
    },
    editMode(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs[`quota_id-${this.index}`].focus();
        });
      }
    },
  },
  methods: {
    updateQuota() {
      this.loading = true;
      QuotaService.updateQuotaById(this.quota.id, {
        name: this.quotaName,
        project_id: this.quota.project_id,
        edit: this.quota.edit,
        custom_status: this.quota.custom_status,
      })
        .then(({ data }) => {
          this.quotaName = data.name;
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.update")
          );
          this.editMode = false;
        })
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.loading = false));
    },
    deleteQuota() {
      QuotaService.deleteQuota(this.quota.id)
        .then(() => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.delete")
          );
          this.$emit("deleteQuota");
        })
        .catch((response) => {
          this.createErrorMessage(response);
        });
    },
    createErrorMessage(response) {
      const parseErrorText = response?.data?.detail.split(" ");
      const errorTypes = {
        quota_rooms: "pieces",
        service_quotas: "services",
        documents: "documents",
      };
      const errorType = parseErrorText[parseErrorText.length - 1];
      const errorInfo = parseErrorText[parseErrorText.length - 2];
      const basicInformation =
        "Vous ne pouvez pas supprimer le devis, vous avez";
      const detailInformation = `${errorInfo} ${errorTypes[errorType]}`;
      const errorMessage = `${basicInformation} ${detailInformation}`;

      this.$store.commit("snackbarModule/SET_ERROR_API", errorMessage);
    },
  },
};
</script>

<style src="./QuotaItem.scss" lang="scss" scoped></style>
